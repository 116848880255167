const defaultState = () => {
  return {
    id: "",
    name: "",
    vin: "",
    description: "",
    addressId: "",
    address1: "",
    address2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
    phone: "",
    fax: "",
    email: "",
    orderEmail: "",
    website: "",
    logoLocation: "",
    returnPolicy: "",
    handlingTime: 0,
    orderCutoff: "",
    orderCutoffTz: "",
    shipsTo: [
      // {
      //   code: '',
      //   name: '',
      // },
    ],
    marketFee: "",
    active: false,
    marketFeeType: "",
    activeUsers: [
      // {
      //   id: '',
      //   firstName: '',
      //   lastName: '',
      //   email: '',
      //   phone: null,
      //   deletedAt: null,
      // },
    ],
    shippingCarrier: "",
    shippingType: "",
  };
};

export default {
  namespaced: true,
  state: {
    vendor: defaultState(),
    pageLength: 20,
    vendorId: "",
  },

  getters: {
    getVendorId: state => {
      return state.vendorId;
    },
    getVendor: state => {
      return state.vendor;
    },
    getPageLength(state) {
      return state.pageLength;
    },
  },
  mutations: {},
  actions: {},
};
