import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

// @ PAYLOAD
// fromAddressId = Current Location
// toAddressId = Current Location, Patient Location Or Vendor Location
const createShippingRateCalculation = async (_, { shippingRateType, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `shipping/v1/shipment/${shippingRateType}`;
  const response = await proxy.execute(query, payload, {
    successNotificationMsg: "Successfully Created Shipping Rate.",
    displaySuccessMessage: false,
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

// Public API
const validateAddressToEasypost = async (_, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = "shipping/v1/pub/validate-address";
  const response = await proxy.execute(query, payload);
  return response;
}

export default {
  createShippingRateCalculation,
  validateAddressToEasypost
};
