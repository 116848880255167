import axios from "@/lib/axios";

export default {
  state: {
    receipts: [],
    locationPage: 1,
    locationPageLength: 20,
  },

  getters: {
    getCurrentLocationReceipts: state => {
      return state.receipts.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      });
    },
    getLocationNumberOfPages(state, getters) {
      const pages = Math.ceil(
        getters.getCurrentLocationReceipts.length / getters.getLocationPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getLocationPageLength(state) {
      return state.locationPageLength;
    },
    getLocationPage(state) {
      return state.locationPage;
    },
    getLocationPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getCurrentLocationReceipts;
      const pageLength = getters.getLocationPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setLocationReceipts(state, payload) {
      state.receipts = payload;
    },
    setPage(state, payload) {
      state.locationPage = payload;
    },
  },

  actions: {
    nextLocationPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevLocationPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitLocationPage({ commit }, payload) {
      commit("setPage", payload);
    },
    // actions
    fetchLocationReceipts: async ({ commit, getters }) => {
      const response = await axios.get(
        `/orders/v1/buyer/${getters.getSelectedCurrentLocationId}/receipt`,
      );
      commit("setLocationReceipts", response.data);
    },
  },
};
