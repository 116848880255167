import { get } from "lodash";

const getReportListData = state => {
  return get(state.reportList, "data", []);
};

const getReportListPagination = state => {
  return get(state.reportList, "paginationDetails", {});
};

const getReportFilter = state => {
  return get(state.reportList, "filters", {});
};

const getReportInitialFilter = state => {
  return get(state.reportList, "initialReportFilters", {});
};

export default {
  getReportListData,
  getReportListPagination,
  getReportFilter,
  getReportInitialFilter,
};
