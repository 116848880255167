import axios from "@/lib/axios";

export default {
  state: {
    receipts: [],
    orgPage: 1,
    pageLength: 25,
    filteredReceipts: [],
  },

  getters: {
    getOrgReceipts: state => {
      return state.receipts.reverse();
    },
    getNumberOfReceiptPages(state, getters) {
      const pages = Math.ceil(
        getters.getFilteredReceipts.length / getters.getReceiptPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getReceiptPageLength(state) {
      return state.pageLength;
    },
    getReceiptPage(state) {
      return state.orgPage;
    },
    getReceiptPageResults(state, getters) {
      const page = state.orgPage;
      const results = getters.getFilteredReceipts;
      const pageLength = getters.getReceiptPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getFilteredReceipts(state) {
      return state.filteredReceipts;
    },
  },

  mutations: {
    setOrgReceipts(state, payload) {
      if (payload === null) {
        state.receipts = [];
      } else {
        state.receipts = payload;
      }
    },
    setPageNumber(state, payload) {
      state.orgPage = payload;
    },
    setFilteredReceipts(state, payload) {
      state.filteredReceipts = payload;
    },
  },

  actions: {
    nextReceiptPage({ state, commit }) {
      commit("setPageNumber", state.orgPage + 1);
    },
    prevReceiptPage({ state, commit }) {
      commit("setPageNumber", state.orgPage - 1);
    },
    commitReceiptPage({ commit }, payload) {
      commit("setPageNumber", payload);
    },
    initiateReceiptPage({ commit }, payload) {
      commit("setPageNumber", payload);
    },
    commitFilteredReceipts({ commit }, payload) {
      commit("setFilteredReceipts", payload);
    },
    // actions
    fetchOrgReceipts: async ({ commit }, orgId) => {
      const response = await axios.get(
        `/receipt/v1/org/${orgId}`,
      );
      commit("setOrgReceipts", response.data);
    },
  },
};
