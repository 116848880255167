import axios from "@/lib/axios";

export default {
  state: {
    features: [],
    newFeature: {
      name: "",
      description: "",
    },
  },

  getters: {
    getAllFeatures: state => { return state.features; },
    getNewFeature: state => { return state.newFeature; },
    getNewFeatureValidated: state => {
      return state.newFeature.name.length >= 1 &&
        state.newFeature.description.length >= 1;
    },
  },

  mutations: {
    setAllFeatures(state, payload) {
      state.features = payload;
    },
    setNewFeature(state, payload) {
      state.newFeature = payload;
    },
  },

  actions: {
    fetchAllFeatures: async ({ commit }) => {
      const response = await axios.get(
        "/subscription/v1/admin/feature",
      );
      commit("setAllFeatures", response.data);
    },
    fetchAllActiveFeatures: async ({ commit }) => {
      const response = await axios.get(
        "/subscription/v1/admin/feature?all=1",
      );
      commit("setAllFeatures", response.data);
    },
    addNewFeature: async ({ dispatch, commit, getters }) => {
      const {
        name, description,
      } = getters.getNewFeature;
      const body = [{
        name,
        description,
      }];
      const response = await axios.post(
        "/subscription/v1/admin/feature",
        body,
      );
      dispatch("resetNewFeature");
      commit("setAllFeatures", response.data);
      return true;
    },
    deleteFeature: async ({ commit }, featureId) => {
      const response = await axios.delete(
        `/subscription/v1/admin/feature/${featureId}?delete=1`,
      );
      commit("setAllFeatures", response.data);
    },
    commitNewFeatureDetails: async ({ commit }, payload) => {
      commit("setNewFeature", payload);
    },
    resetNewFeature: ({ commit }) => {
      commit("setNewFeature", {
        name: "",
        description: "",
      });
    },
  },
};
