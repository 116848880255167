import axios from "@/lib/axios";
import globalUtil from "@/utils/global";

export default {
  namespaced: true,

  state: {
    loading: false,
    categories: [],
    category: {
      name: "",
      slug: "",
      description: "",
    },
    results: [],
    page: 1,
    pageLength: 24,
    priceRange: [0, 0],
  },

  getters: {
    getCategories: state => {
      return state.categories;
    },
    getPriceRange: state => {
      return state.priceRange;
    },
    getPriceFilteredResults: state => {
      return state.results.filter(p => {
        return p.price >= state.priceRange[0] && p.price <= state.priceRange[1];
      });
    },
    getHighestPrice: state => {
      if (state.results.length > 0) {
        const products = state.results;
        const sorted = products.sort((a, b) => {
          if (a.price > b.price) {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          return 0;
        });
        return sorted[sorted.length - 1].price;
      }
      return 0;
    },
    getCategory: state => {
      return state.category;
    },
    getResults: (state, getters) => {
      return getters.getPriceFilteredResults;
    },
    getLoadingStatus: state => {
      return state.loading;
    },
    getNumberOfPages(state, getters) {
      const pages = Math.ceil(getters.getResults.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getResults;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },
    setCategory(state, payload) {
      state.category = payload;
    },
    setPriceRange(state, payload) {
      state.priceRange = payload;
    },
    setResults(state, payload) {
      state.results = payload;
    },
    setLoadingStatus(state, payload) {
      state.loading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
  },

  actions: {
    commitSetCategory: async ({ commit }, category) => {
      await commit("setCategory", category);
    },
    commitSetPriceRange: async ({ commit }, priceRange) => {
      await commit("setPriceRange", priceRange);
    },
    fetchCategories: async ({ commit }) => {
      const response = await axios.get("/products/v1/market/category");
      commit("setCategories", response.data);
    },
    fetchProductsByCategorySlug: async ({ commit, getters }) => {
      await commit("setLoadingStatus", true);
      const response = await axios.get(`/products/v1/market/category/${getters.getCategory.slug}`);
      commit("setResults", response.data);
      await commit("setLoadingStatus", false);
    },
    commitSetLoadingStatus: ({ commit }, status) => {
      commit("setLoadingStatus", status);
    },
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
    addToCart: async ({ commit, rootGetters, dispatch }, payload) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: 1,
        };
        const response = await axios.post(`/orders/v1/cart/${rootGetters.getSelectedCurrentLocationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
  },
};
