import { get } from "lodash";

// Generic function to set data and pagination details
const setDataAndPagination = (state, key, payload) => {
  if (payload) {
    const data = get(payload, "data", []);
    state[key].paginationDetails.currentPage = get(payload, "pageNumber", 1);
    state[key].paginationDetails.totalPages = get(payload, "totalPages", 0);
    state[key].paginationDetails.pageSize = get(payload, "size", 10);
    state[key].paginationDetails.totalData = get(payload, "totalData", 0);
    state[key].data = data;
  } else {
    state[key].data = [];
    state[key].paginationDetails = state.defaultPaginationDetails;
  }
};

const setAccessRights = (state, payload) => {
  setDataAndPagination(state, "accessRights", payload);
};

const setAccessRightPermissions = state => {
  const sections = state.sections;
  const accessRights = state.accessRights.data;

  if (accessRights.length > 0) {
    sections.data.map(data => {
      // Filter access rights that match the current section's id
      const accessRightsData = accessRights.filter(access => access.section.id === data.id);
      data.accessRights = accessRightsData; // Assign the filtered access rights to the section
      return data; // Return the modified section
    });
  }
  setDataAndPagination(state, "sectionAccessRightPermissions", sections);
};

const setSections = (state, payload) => {
  setDataAndPagination(state, "sections", payload);
};

const setUserSectionsAccess = (state, payload) => {
  const userPermissions = payload || [];
  // Initialize sections if not already present
  if (!state.sections.data && state.sections.data.length < 1) {
    state.sections = [];
  }
  state.sections.data.forEach(section => {
    const userPerm = userPermissions.find(perm => perm.sectionId === section.id);
    if (userPerm) {
      section.selectedAccessRight = {
        id: userPerm.id,
        access: userPerm.access,
      };
    } else {
      const defaultValue = {
        id: null,
        access: undefined,
      };
      section.selectedAccessRight = defaultValue;
    }
  });
};

const setPermissions = (state, payload) => {
  setDataAndPagination(state, "permissions", payload);
};

const setAccessRightsUsers = (state, payload) => {
  setDataAndPagination(state, "users", payload);
};

// SETTER OF ACCESS RIGHTS ON USERS TABLE
// const setUserAccessRights = (state, payload) => {
//   state.sections.data.forEach((data) => {
//     const accessRights = state.sections.data.find(section => section.id === permission.sectionId);
//   })
// setDataAndPagination(state, "users", payload);
// payload.forEach(permission => {
//   // Find the section that matches the sectionId in the permission
//   const section = state.sections.data.find(section => section.id === permission.sectionId);

//   // If the section exists, add the access right
//   if (section) {
//     // Add the permission to the access rights array
//     section.selectedAccessRight = {
//       id: permission.id,
//       access: permission.access,
//     };
//   } else {
//     // If the section does not exist, you may choose to create it or handle it accordingly
//     console.warn(`Section with ID ${permission.sectionId} not found.`);
//   }
// });

//     // Initialize the access rights array if it doesn't exist
//     if (!section.accessRights) {
//       section.accessRights = [];
//     }
// };

const updateUserSectionsAccess = (state, payload) => {
  state.sections.data[payload.sectionIndex].selectedAccessRight = payload.value;
};

export default {
  setAccessRights,
  setAccessRightPermissions,
  setSections,
  setPermissions,
  setAccessRightsUsers,
  // setUserAccessRights,
  setUserSectionsAccess,
  updateUserSectionsAccess,
};
