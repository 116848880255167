const setLocationOrders = (state, payload) => {
  state.orders = payload;
};

const setPage = (state, payload) => {
  state.page = payload;
};

export default {
  setLocationOrders,
  setPage
};
