import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
// UTILS
import notification from "@/utils/notifications";
// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";
import axios from "axios";
import { getInstance } from "@/auth";
import store from "@/store";

const idProofingError = errorMessage => {
  const isMismatchedNPI = errorMessage.toLowerCase().includes("npi number mismatch".toLowerCase());
  const isMismatchedDea = errorMessage.toLowerCase().includes("dea number mismatch".toLowerCase());
  const isUnverified = errorMessage.toLowerCase().includes("Authorization Required".toLowerCase());
  if (isUnverified) {
    notification(
      NOTIFICATION_CONSTANTS.ERROR,
      "Authorization Required!",
      "User must authorize Biote to receive your identity information to gain access to BioteRx. You will   be taken back to the beginning of the onboarding process and can authorize Biote to receive your identity information. If you believe you received this message in error, please contact BioteRx customer service at BioteRx@biote.com or 1-844-980-0683.",
      isUnverified ? 15 : 4,
    );
  } else {
    notification(NOTIFICATION_CONSTANTS.ERROR, errorMessage, "", isMismatchedNPI ? 10 : 4);
  }
  return { isMismatchedNPI, isMismatchedDea, isUnverified };
};

const signupVendor = async (_, payload) => {
  if (!payload.invitationToken) {
    return;
  }
  const proxy = new PostSimpatraServiceProxy();
  const query = "j-idp/v1/pub/vendor/sign-up";
  return await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully created vendor!",
    },
  });
};

const idProofUser = async ({ commit }, payload) => {
  try {
    const proxy = new PostSimpatraServiceProxy();
    const query = "j-idp/v1/id-proofing";
    const response = await proxy.execute(query, payload, null, {
      addedHeaders: {
        "idme-code": payload.idMeCode || null,
      },
    });
    if (response) {
      let notificationHeader = "Successfully ID Proofed with EPCS!";
      let notificationMessage = "User has been granted controlled substance permission";
      if (!payload.isAccepted) {
        notificationHeader = "Successfully denied invitation!";
        notificationMessage = "Controlled substance permission has been denied.";
      }
      notification(NOTIFICATION_CONSTANTS.SUCCESS, notificationHeader, notificationMessage);
      commit("setUserIdentity", { type: "update", fieldName: "isControlledSubstanceAcceptanceVisibility", fieldValue: false });
    }
  } catch (error) {
    const errorMessage = catchError(error);
    idProofingError(errorMessage);
  }
};

const onboardOrganization = async ({ commit }, payload) => {
  if (!payload) {
    return;
  }
  try {
    const authService = await getInstance();
    const accessToken = await authService.getAccessTokenSilently();
    const config = {
      headers: {
        Accept: "application/json",
        "biote-auth": accessToken,
      },
    };
    // Response: accessToken
    const query = `${process.env.VUE_APP_API_ROOT}/j-idp/v1/biote/org/onboarding`;
    const response = await axios.post(query, payload, config);
    if (response) {
      commit("setUserIdentity", { type: "update", fieldName: "isUserOnboarded", fieldValue: true });
    }
    return response;
  } catch (error) {
    if (error && error.response) {
      const errorMessage = catchError(error);
      const { isMismatchedNPI, isUnverified } = idProofingError(errorMessage);
      if (isMismatchedNPI) {
        return { npiError: true };
      } else if (isUnverified) {
        return { verified: false };
      }
    }
    return {
      data: null,
      message: error,
    };
  }
};

const fetchBioteUserDetails = async () => {
  try {
    const authService = await getInstance();
    const accessToken = await authService.getAccessTokenSilently();
    // NEED TO OPTIMIZE THIS CODE... PARTIALLY MADE THIS WAY
    const config = {
      headers: {
        Accept: "application/json",
        "biote-auth": accessToken,
      },
    };
    const query = `${process.env.VUE_APP_API_ROOT}/j-idp/v1/biote/user/get-info`;
    const response = await axios.get(query, config);
    return response.data;
  } catch (error) {
    if (error && error.response) {
      const errorMessage = catchError(error);
      notification(NOTIFICATION_CONSTANTS.ERROR, errorMessage);
    }
    return null;
  }
};

const validateAuth0Token = async (_, { accessToken, idMeCode }) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "biote-auth": accessToken,
        ...(idMeCode && { "idme-code": idMeCode }), // Need to remove this in non onboarded user
      },
    };
    const query = `${process.env.VUE_APP_API_ROOT}/j-idp/v1/pub/biote/validate-user`;
    const result = await axios.post(query, {}, config);
    return result.data;
  } catch (error) {
    console.log("Error during validation of auth0 token", error);
    if (error && error.response) {
      const errorMessage = catchError(error);
      const isMismatchedNPI = errorMessage.toLowerCase().includes("npi number mismatch".toLowerCase());
      const isUnverified = errorMessage.toLowerCase().includes("Authorization Required".toLowerCase());
      if (isUnverified) {
        notification(
          NOTIFICATION_CONSTANTS.ERROR,
          "Authorization Required!",
          "User must authorize Biote to receive your identity information to gain access to BioteRx. You will be taken back to the beginning of the onboarding process and can authorize Biote to receive your identity information. If you believe you received this message in error, please contact BioteRx customer service at BioteRx@biote.com or 1-844-980-0683.",
          isUnverified ? 15 : 4,
        );
      } else {
        const message = error.response.message || error.response.error || error.response.error_description || error.response.message || null;
        notification(NOTIFICATION_CONSTANTS.ERROR, errorMessage, message, isMismatchedNPI ? 10 : 4);
      }
      if (isMismatchedNPI) {
        setTimeout(async () => {
          await logoutUser();
        }, 10000);
      } else {
        setTimeout(async () => {
          await logoutUser();
        }, 3500);
      }
    }
    return null;
  }
};

const identifyUserIdProofing = async ({ commit }, accessToken) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "biote-auth": accessToken,
      },
    };
    const query = `${process.env.VUE_APP_API_ROOT}/j-idp/v1/pub/biote-user-info`;
    const result = await axios.post(query, {}, config);
    commit("setUserIdentity", result.data);
    return result.data;
  } catch (error) {
    console.log("Error during validation of auth0 token", error);
    if (error && error.response) {
      const errorMessage = catchError(error);
      const message = error.response.message || error.response.error || error.response.error_description || error.response.message || null;
      notification(NOTIFICATION_CONSTANTS.ERROR, errorMessage, message);
    }
    return null;
  }
};

const verifyUser = async (_, { accessToken, code }) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "biote-auth": accessToken,
      },
    };
    const query = `${process.env.VUE_APP_API_ROOT}/j-idp/v1/idme/verify-user`;
    const result = await axios.post(query, { code }, config);
    return result.data;
  } catch (error) {
    console.log("Error during validation of auth0 token", error);
    if (error && error.response) {
      const errorMessage = catchError(error);
      notification(NOTIFICATION_CONSTANTS.ERROR, errorMessage);
    }
    return null;
  }
};

const generateIDMeUrl = (_, payload) => {
  try {
    const scope = payload || process.env.VUE_APP_ID_ME_SCOPE;
    const endpoint = process.env.VUE_APP_ID_ME_ENDPOINT;
    const parameters = [
      `client_id=${process.env.VUE_APP_ID_ME_CLIENT_ID}`,
      `redirect_uri=${process.env.VUE_APP_ID_ME_REDIRECT_URI}`,
      "response_type=code",
      `scope=openid%20${scope}`,
    ];
    const idMeEndpoint = `${endpoint}?${parameters.join("&")}`;
    return idMeEndpoint;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const catchError = error => {
  if (error && error.response) {
    const { data, status } = error.response;
    const message = data.message || data.error || "Unknown error";
    // const errorMessage = `Message: ${message}, Status Code: ${status}`;
    console.error("Message:", message);
    console.error("Status Code:", status);
    return message;
  }
  return null;
};

const logoutUser = async () => {
  const authService = await getInstance();
  await store.dispatch("clearLocalStore");
  localStorage.removeItem("selected_vendor");
  localStorage.removeItem("orgId");
  localStorage.removeItem("currentLocation");
  sessionStorage.removeItem("carouselClosed");
  const { logout } = await authService.$auth;
  await logout();
};

export default {
  signupVendor,
  onboardOrganization,
  fetchBioteUserDetails,
  validateAuth0Token,
  identifyUserIdProofing,
  verifyUser,
  generateIDMeUrl,
  logoutUser,
  idProofUser,
};
