import { isEmpty } from "lodash";
import axios from "@/lib/axios";
import notification from "@/utils/notifications";

export default {
  namespaced: true,

  state: {
    loading: false,
    query: "",
    category: "all-products",
    results: [],
    page: 1,
    pageLength: 20,
    priceRange: [0, 0],
    similarResults: [],
    bestResults: [],
    sellerList: [],
    selectedSeller: undefined,
  },

  getters: {
    getQuery: state => {
      return state.query;
    },
    getCategory: state => {
      return state.category;
    },
    getPriceRange: state => {
      return state.priceRange;
    },
    getPriceFilteredResults: state => {
      if (state.results.length > 0) {
        return state.results.filter(p => {
          return p.price >= state.priceRange[0] && p.price <= state.priceRange[1];
        });
      }
    },
    getHighestPrice: state => {
      if (state.results !== undefined) {
        if (state.results.length > 0) {
          const products = state.results;
          const sorted = products.sort((a, b) => {
            if (a.price > b.price) {
              return 1;
            }
            if (a.price < b.price) {
              return -1;
            }
            return 0;
          });
          return sorted[sorted.length - 1].price;
        }
      }
      return 0;
    },
    getSelectedSeller: state => {
      return state.selectedSeller;
    },
    getResults: (state, getters) => {
      if (getters.getPriceFilteredResults !== undefined) {
        if (getters.getSelectedSeller !== undefined) {
          const data = getters.getPriceFilteredResults;
          return data.filter(d => {
            return d.vendorId === getters.getSelectedSeller;
          });
        }
        return getters.getPriceFilteredResults;
      }
      return "";
    },
    getSimilarResults: state => {
      return state.similarResults;
    },
    getSellerList: state => {
      return state.sellerList;
    },
    getBestResults: state => {
      return state.bestResults;
    },
    getLoadingStatus: state => {
      return state.loading;
    },
    getNumberOfPages(state, getters) {
      let pages = 0;
      if (getters.getResults !== undefined) {
        pages = Math.ceil(getters.getResults.length / getters.getPageLength);
      }
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfSimilarPages(state, getters) {
      const pages = Math.ceil(getters.getSimilarResults.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getNumberOfBestPages(state, getters) {
      const pages = Math.ceil(getters.getBestResults.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getResults;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getSimilarPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getSimilarResults;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getBestPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getBestResults;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setQuery(state, payload) {
      state.query = payload;
    },
    setCategory(state, payload) {
      state.category = payload;
    },
    setPriceRange(state, payload) {
      state.priceRange = payload;
    },
    setSelectedSeller(state, payload) {
      state.selectedSeller = payload;
    },
    setResults(state, payload) {
      if (payload.search) {
        state.results = payload.search;
        state.similarResults = payload.similar;
        state.sellerList = payload.sellerList;
      }
      if (!payload.search) {
        state.results = payload;
        if (payload["Search Results"]) {
          state.results = payload["Search Results"];
          state.sellerList = payload.sellerList;
        }
      }
    },
    setLoadingStatus(state, payload) {
      state.loading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
  },

  actions: {
    commitSetQuery: ({ commit }, query) => {
      commit("setQuery", query);
    },
    commitSetCategory: ({ commit }, categoryId) => {
      commit("setCategory", categoryId);
    },
    commitSetPriceRange: ({ commit }, priceRange) => {
      commit("setPriceRange", priceRange);
    },
    commitSelectedSellet: ({ commit }, selectedSeller) => {
      commit("setSelectedSeller", selectedSeller);
    },
    fetchProductsByQuery: async ({ commit, getters, rootGetters }) => {
      const orgId = localStorage.getItem("orgId");
      const locationId = rootGetters.getSelectedCurrentLocationId;
      commit("setSelectedSeller", undefined);
      commit("setPriceRange", [0, getters.getHighestPrice]);
      await commit("setLoadingStatus", true);
      let response;
      if (getters.getCategory === "all-products") {
        const isLoggedIn = rootGetters["usersModule/isLoggedIn"];
        let query = `/products/v1/market/isearch?q=${getters.getQuery}`;
        if (isLoggedIn) {
          query = `/products/v1/location/${locationId}/market/isearch?q=${getters.getQuery}&orgId=${orgId}`;
        }
        response = await axios.get(query);
      } else {
        response = await axios.get(`/products/v1/market/search?q=${getters.getQuery}&c=${getters.getCategory}`);
      }
      commit("setResults", response.data);
      commit("setLoadingStatus", false);
      commit("setQuery", "");
    },
    commitSetLoadingStatus: ({ commit }, status) => {
      commit("setLoadingStatus", status);
    },
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
    addMinQtyToCart: async ({ commit }, payload) => {
      if (!isEmpty(localStorage.locationStatus) && localStorage.locationStatus === "active") {
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: payload.qty,
        };
        try {
          const response = await axios.post(`/orders/v1/cart/${localStorage.currentLocation}/item`, body);
          commit("setCartItems", response.data, { root: true });
          commit("setAddToCartQty", payload.minQtyForPurchase);
          notification("success", `The product ${payload.productName} successfully added into the cart.`);
        } catch (error) {
          notification("warning", error);
        }
      } else {
        notification("warning", "Selected location deactivated!");
      }
    },
    addToCart: async ({ commit, rootGetters, dispatch }, payload) => {
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      const locationList = rootGetters["accountsModule/getOrgLocations"];
      const isActiveFlag = locationList.filter(l => {
        if (l.id === rootGetters.getSelectedCurrentLocationId) {
          return true;
        }
        return false;
      });
      if (isActiveFlag[0].isActive === true) {
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: 1,
        };
        const response = await axios.post(`/orders/v1/cart/${rootGetters.getSelectedCurrentLocationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
  },
};
