// Formatted Data
const getSectionAccessRightPermissions = state => state.sectionAccessRightPermissions;

const getAccessRights = state => state.accessRights;

const getSections = state => state.sections;

const getUserRolesPermissionsUsers = state => state.users;

export default {
  getSectionAccessRightPermissions,
  getAccessRights,
  getSections,
  getUserRolesPermissionsUsers,
};
