import axios from "@/lib/axios";
import globalUtil from "@/utils/global";

const defaultState = () => {
  return {
    id: "",
    minQtyForPurchase: 0,
    productPricingId: "",
    addItemToCart: false,
  };
};

export default {
  namespaced: true,

  state: {
    product: defaultState(),
    addToCartQty: 1,
  },

  getters: {
    getProduct: state => {
      return state.product;
    },
    getAddToCartQty: state => {
      return state.addToCartQty;
    },
  },

  mutations: {
    setProduct(state, payload) {
      state.product = payload;
    },
    setAddToCartQty(state, payload) {
      state.addToCartQty = payload;
    },
    setCartItems(state) {
      state.addItemToCart = true;
    },
  },

  actions: {
    commitItemToCart: async ({ commit }, payload) => {
      const products = [];
      payload.forEach(item => {
        products.push(item);
      });
      commit("setCartItems", products, { root: true });
      commit("setAddToCartQty", 1);
    },
    fetchProductById: async ({ commit }, productId) => {
      const response = await axios.get(`/products/v1/market/product/${productId}`);
      commit("setProduct", response.data);
    },
    addItemToCart: async ({ commit, rootGetters, dispatch }, data) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: data.productId,
          productPricingId: data.productPricingId,
          qty: data.qty,
        };
        const response = await axios.post(`/orders/v1/cart/${data.locationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", 1);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
    addLocalProductsToCart: async ({ commit, getters, rootGetters, dispatch }, payload) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: payload.qty,
        };
        const response = await axios.post(`/orders/v1/cart/${payload.locationId}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", getters.getProduct.minQtyForPurchase);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
    addToCart: async ({ commit, getters, rootGetters, dispatch }) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const body = {
          productId: getters.getSelectedProduct.id,
          qty: getters.getAddToCartQty,
        };
        const response = await axios.post(`/orders/v1/cart/${rootGetters.getCurrentSelectedLocation}/item`, body);
        commit("setCartItems", response.data, { root: true });
        commit("setAddToCartQty", getters.getProduct.minQtyForPurchase);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
  },
};
