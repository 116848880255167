import store from "@/store";

const PAYLOAD_TYPE = {
  INITIALIZE: "initialize",
  UPDATE: "update",
  ADD: "add",
  EMPTY: "empty",
};

const setPreOrdersCartItems = (state, payload) => {
  if (payload) {
    const { data, totalData, totalPages, size } = payload;
    state.preOrdersCart = {
      data,
      paginationDetails: {
        totalData,
        totalPages,
        pageSize: size,
      },
    };
  } else {
    state.preOrdersCart = {
      data: [],
      paginationDetails: state.defaultPaginationDetails,
    };
  }
};

const removeProductFromDraftCart = (state, payload) => {
  state.preOrdersCart.data.splice(payload, 1);
};

const setPrescriptionCart = async (state, payload) => {
  if (payload) {
    if (payload.type === PAYLOAD_TYPE.INITIALIZE) {
      const data = await setPrescriptionCartFulfillmentInitialValues(state, payload.data);
      state.prescriptionCart.data = data || null;
    } else if (payload.type === PAYLOAD_TYPE.EMPTY) {
      // Reset
      state.prescriptionCart.data = null;
      state.prescriptionCart.userLicenses = [];
      state.prescriptionCart.fulfillmentsShippings = [];
    }
  }
};

const setPrescriptionCartFulfillmentInitialValues = async (state, payload) => {
  // Reset State
  state.prescriptionCart.data = null;
  state.prescriptionCart.userLicenses = [];
  state.prescriptionCart.fulfillmentsShippings = [];
  const userDetails = store.getters["usersModule/getMyInfo"];
  const { fulfillments } = payload;
  const userLicenses = [];
  const fulfillmentsShippings = [];
  if (fulfillments.length > 0) {
    const fulfillmentsPromises = fulfillments.map(async item => {
      const shippingPayload = {
        shippingRateId: undefined,
        shippingRates: null,
        fulfillmentId: item.fulfillmentId,
        addressId: item.vendor.addressId,
      };
      fulfillmentsShippings.push(shippingPayload);
      userLicenses.push({
        userId: userDetails.sim.user.subject || null,
        vendorId: item.vendor.id,
      });
      item.selectedProvider = userDetails.sim.user.subject || null;
      item.shippingRateId = undefined;
      item.shippingRates = [];
      item.finalized = false;
      item.shippingTo = null;
      item.shipToId = undefined;
    });
    await Promise.all(fulfillmentsPromises);
  }
  state.prescriptionCart.fulfillmentsShippings = fulfillmentsShippings;
  state.prescriptionCart.userLicenses = userLicenses;
  return payload;
};

const setPrescriptionShipping = (state, { fulfillmentIndex, newValue, type }) => {
  const shipping = state.prescriptionCart.fulfillmentsShippings[fulfillmentIndex];
  const fulfillmentVendor = state.prescriptionCart.data.fulfillments[fulfillmentIndex].vendor;

  switch (type) {
    case "clear":
      Object.assign(shipping, { shippingRateId: undefined, shippingRates: null, rate: 0 });
      break;
    case "add":
      state.prescriptionCart.fulfillmentsShippings.push(newValue);
      break;
    case "delete":
      state.prescriptionCart.fulfillmentsShippings.splice(fulfillmentIndex, 1);
      break;
    case "replace":
      Object.assign(shipping, { shippingRateId: newValue.shippingRateId, shippingRates: newValue.shippingRates });
      break;
    default:
      fulfillmentVendor.shippingService = newValue.serviceName;
      fulfillmentVendor.shippingCharges = newValue.rate;
      state.prescriptionCart.data.fulfillments[fulfillmentIndex].vendor = fulfillmentVendor;
      Object.assign(shipping, { shippingRateId: newValue.shippingRateId, rate: newValue.rate });
      break;
  }
};

const setPrescriptionLicense = (state, { userIndex, value, type }) => {
  switch (type) {
    case "add":
      state.prescriptionCart.userLicenses.push(value);
      break;
    case "remove":
      state.prescriptionCart.userLicenses.splice(userIndex, 1);
      break;
    default:
      state.prescriptionCart.userLicenses[userIndex] = value;
      break;
  }
};

const handlePrescriptionFulfillmentUpdate = (state, {
  type,
  data,
  fulfillmentIndex,
  subPropItemIndex,
  prop,
  subProp
}) => {
  const fulfillment = state.prescriptionCart.data.fulfillments[fulfillmentIndex];

  switch (type) {
    case "edit":
      if (subPropItemIndex >= 0) {
        fulfillment[prop][subPropItemIndex][subProp] = data;
      } else if (subProp) {
        fulfillment[prop][subProp] = data;
      } else {
        fulfillment[prop] = data;
      }
      break;
    case "delete":
      if (subPropItemIndex >= 0) {
        fulfillment[prop].splice(subPropItemIndex, 1);
      } else {
        state.prescriptionCart.data.fulfillments.splice(fulfillmentIndex, 1);
      }
      break;
    default:
      fulfillment[prop] = data
      break;
  }
};

const updateCartTotals = (state, { fulfillmentIndex, newFulfillmentTotal, newGrandTotal }) => {
  if (fulfillmentIndex >= 0 && fulfillmentIndex !== undefined) {
    const fulfillment = state.prescriptionCart.data.fulfillments[fulfillmentIndex];
    fulfillment.grandTotal = newFulfillmentTotal;
  }
  state.prescriptionCart.data.grandTotal = newGrandTotal;
};

export default {
  setPreOrdersCartItems,
  removeProductFromDraftCart,
  setPrescriptionCart,
  setPrescriptionShipping,
  setPrescriptionLicense,
  handlePrescriptionFulfillmentUpdate,
  updateCartTotals,
};
