import axios from "@/lib/axios";
import notification from "@/utils/notifications";

export default {
  namespaced: true,
  state: {
    shippingRates: [],
    orders: [],
    labPatients: [],
    page: 1,
    pageLength: 15,
    locationId: "",
  },

  getters: {
    getShippingAppliedRate: state => {
      return state.shippingRates;
    },
    getLocationId: state => {
      return state.locationId;
    },
    getLabPatients: state => {
      return state.labPatients;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getLabPatientsPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getLabPatients;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
    getLocationOrders(state) {
      return state.orders.sort((a, b) => {
        const aDate = new Date(a.paidAt);
        const bDate = new Date(b.paidAt);
        return bDate - aDate;
      });
    },
  },

  mutations: {
    setShippingAppliedRate(state, payload) {
      state.shippingRates = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
  },

  actions: {
    fetchShippingAppliedRate: async ({ commit }, fulfillmentId) => {
      const response = await axios.get(`/orders/v1/fulfillment/${fulfillmentId}/medcenterShipp`);
      commit("setShippingAppliedRate", response.data);
    },
    cancelCurrentVendorOrder: async ({ commit, getters }) => {
      const vendorId = getters.getVendorId;
      const { fulfillmentId } = getters.getVendorOrderDetails;
      const response = await axios.post(
        `/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/cancel`,
        {},
      );
      commit("setVendorOrderDetails", response.data);
    },
    // Add
    createShippingLabels: async ({ commit }, payload) => {
      const { vendorId } = payload;
      const { fulfillmentId } = payload;
      const body = payload.packages; // where packages is an array of package dim objects

      const response = await axios.post(
        `/orders/v1/vendor/${vendorId}/fulfillment/${fulfillmentId}/parcel`,
        body,
      );

      commit("setVendorOrderDetails", response.data);
    },
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
  },
};
