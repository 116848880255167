import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";

const fetchCreditCard = async ({ commit }, { idType, id, initOrgCCState, additionalQueryParams = {} }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "payments/v1/cards";
  const queryParams = {
    [idType]: id,
    ...additionalQueryParams,
  };
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    if (initOrgCCState) {
      commit("setOrgCCList", data);
    }
    return data;
  }
};

const addEditDeleteCreditCard = async ({ commit }, { payloadId, type, payload, formType, setState, actionType }) => {
  const postProxy = new PostSimpatraServiceProxy();
  const putProxy = new PutSimpatraServiceProxy();
  const deleteProxy = new DeleteSimpatraServiceProxy();
  let query = "";
  if (type === "delete") {
    query = `payments/v1/card/${payloadId}`;
  } else {
    query = `payments/v1/card/${type}/${payloadId}`;
  }
  let response = null;

  if (formType === "update") {
    const successNotificationMsg = "Successfully updated credit card!";
    response = await putProxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg,
    });
  } else if (formType === "delete") {
    response = await deleteProxy.execute(query, {
      notificationParams: {
        successNotificationMsg: "Successfully deleted credit card!",
        displaySuccessMessage: true,
      },
    });
  } else {
    response = await postProxy.execute(query, payload, {
      successNotificationMsg: "Successfully added credit card!",
      displaySuccessMessage: true,
    });
  }
  if (response) {
    const { data } = response;
    if (setState) {
      commit("setOrgCCList", {
        actionType,
        data,
      });
    }
    return data;
  }
};

export default {
  fetchCreditCard,
  addEditDeleteCreditCard,
};
