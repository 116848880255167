const defaultPaginationDetails = {
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
  totalData: 0,
};

const initialReportFilters = {
  // purchaseType: undefined,
  // paymentType: undefined,
  // dateRange: undefined,
  // generatedId: "",
  // orgId: undefined,
  // locationId: undefined,
  // reportType: "order",
  // CUSTOMS
  // selectedOrganization: undefined,
};

const createObject = () => ({
  data: [],
  initialReportFilters,
  filters: initialReportFilters,
  paginationDetails: defaultPaginationDetails,
});

export default {
  defaultPaginationDetails,
  sections: createObject(),
  permissions: createObject(),
  sectionAccessRightPermissions: createObject(),
  accessRights: createObject(),
  users: createObject(),
};
