import axios from "@/lib/axios";

export default {
  state: {
    plans: [],
    newPlan: {
      name: "",
      description: "",
      basePrice: "",
      billingPeriod: "",
      billingPeriodType: "",
    },
  },

  getters: {
    getAllPlans: state => { return state.plans; },
    getNewPlan: state => { return state.newPlan; },
  },

  mutations: {
    setAllPlans(state, payload) {
      state.plans = payload;
    },
    setNewPlan(state, payload) {
      state.newPlan = payload;
    },
  },

  actions: {
    fetchAllPlans: async ({ commit }) => {
      const response = await axios.get(
        "/subscription/v1/admin/plan",
      );
      commit("setAllPlans", response.data);
    },
    fetchAllActivePlans: async ({ commit }) => {
      const response = await axios.get(
        "/subscription/v1/admin/plan?all=1",
      );
      commit("setAllPlans", response.data);
    },
    // This method returns only enabled plans
    fetchEnabledPlansOnly: async ({ commit }) => {
      const response = await axios.get(
        "/subscription/v1/admin/plan?all=0",
      );
      commit("setAllPlans", response.data);
    },
    addNewPlan: async ({ dispatch, commit }, payload) => {
      const response = await axios.post(
        "/subscription/v1/admin/plan",
        payload,
      );
      dispatch("resetNewPlan");
      commit("setAllPlans", response.data);
      return true;
    },
    disablePlan: async ({ commit }, planId) => {
      const response = await axios.delete(
        `/subscription/v1/admin/plan/${planId}`,
      );
      commit("setAllPlans", response.data);
    },
    deletePlan: async ({ commit }, planId) => {
      const response = await axios.delete(
        `/subscription/v1/admin/plan/${planId}?delete=1`,
      );
      commit("setAllPlans", response.data);
    },
    commitNewPlanDetails: async ({ commit }, payload) => {
      commit("setNewPlan", payload);
    },
    resetNewPlan: ({ commit }) => {
      commit("setNewPlan", {
        name: "",
        description: "",
        basePrice: "",
        billingPeriod: "",
        billingPeriodType: "",
      });
    },
  },
};
