import { get } from "lodash";
import STATUS_ID_CONSTANTS from "@/constants/status-id-constants";

const setVendorInvoices = (state, payload) => {
  const data = get(payload, "content", []);
  state.vendorInvoices.paginationDetails.currentPage = get(payload, "number", 0);
  state.vendorInvoices.paginationDetails.totalPages = get(payload, "totalPages", 0);
  state.vendorInvoices.paginationDetails.pageSize = get(payload, "size", 0);
  state.vendorInvoices.paginationDetails.totalData = get(payload, "totalElements", 0);
  state.vendorInvoices.data = data;
};

const updateOrder = (state, payload) => {
  const { orderId, type } = payload;
  const { INVOICE_STATUSES } = STATUS_ID_CONSTANTS;
  const { FULFILLMENT_STATUSES, ORDER_STATUSES } = INVOICE_STATUSES;
  if (type === "update_status") {
    const orderIndex = state.vendorInvoices.data.findIndex(item => item.orderNo === orderId);
    if (orderIndex >= 0) {
      const order = state.vendorInvoices.data[orderIndex];
      const currentStatus = order.fulfillment.status;

      const newStatus = [FULFILLMENT_STATUSES.PENDING.value].includes(currentStatus)
        ? FULFILLMENT_STATUSES.PROCESSING.value
        : currentStatus;
      state.vendorInvoices.data[orderIndex].fulfillment.status = newStatus;
    }
  }
};

export default {
  setVendorInvoices,
  updateOrder,
};
