import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import jwtdecode from "jwt-decode";

/**
 * USING DECODED DATA IN THE TOKEN
 * This is for security purpose
 */
const initializeCurrentLoggedInInfo = ({ commit }, payload) => {
  commit("setCurrentLoggedInInfo", payload);
}

const fetchLoggedUserAccessibleLocations = async (_, queryParams = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  // orgId query only works for super admin whenever user is trying to access other's org
  const query = "accounts/v1/user/location-access";
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const initializeLoginDetails = (_, loginDetails) => {
  // eslint-disable-next-line camelcase
  const { access_token } = loginDetails;
  const decodedToken = jwtdecode(access_token);
  const { id } = decodedToken.sim.details; // orgId or vendorId
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("exp", decodedToken.exp);
  localStorage.removeItem("signatureImage");
  localStorage.setItem("orgId", id);
  return decodedToken;
};

const fetchMyUserInfo = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const accessToken = localStorage.getItem("access_token")
  const decodedToken = jwtdecode(accessToken);
  const { id } = decodedToken.sim.details; // orgId or vendorId
  const userId = decodedToken.sim.user.subject; // orgId or vendorId
  const query = `accounts/v1/org/${id}/user/${userId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setCurrentUserInfo", data || null);
    return data;
  }
};

// user/location-access
export default {
  initializeCurrentLoggedInInfo,
  fetchLoggedUserAccessibleLocations,
  initializeLoginDetails,
  fetchMyUserInfo,
}
