export default {
  state: {
    offCanvasMenu: false,
    requireLocationDialog: false,
  },

  getters: {
    getOffCanvasMenuStatus: state => {
      return state.offCanvasMenu;
    },
    getRequireLocationDialog: state => {
      return state.requireLocationDialog;
    },
  },

  mutations: {
    setRequireLocationDialog(state, payload) {
      state.requireLocationDialog = payload;
    },
    toggleOffCanvasMenu(state, payload) {
      state.offCanvasMenu = payload.toggle;
    },
  },

  actions: {
    commitSetRequireLocationDialog: ({ commit }, payload) => {
      commit("setRequireLocationDialog", payload);
    },
  },
};
