export default {
  namespaced: true,
  state: {
    page: 1,
    pageLength: 25,
  },

  getters: {
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
  },

  mutations: {},

  actions: {
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
  },
};
