import { get, merge } from "lodash";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

// Helper function to extract pagination details
const extractPaginationDetails = (responseData, queryParams) => ({
  pageNumber: queryParams.pageNumber ? queryParams.pageNumber + 1 : 1,
  size: get(responseData, "pageSize", 10),
  totalPages: get(responseData, "totalPages", 0),
  totalData: get(responseData, "totalData", 0),
});

// Helper function to commit sections or permissions
const commitData = (commit, setStore, responseData, paginationDetails) => {
  const data = Array.isArray(responseData) ? responseData : get(responseData, "content", []);
  commit(setStore, merge({ data }, paginationDetails));
  return data;
};

const fetchSections = async ({ commit, dispatch }, { queryParams = {} } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "user-access-control/v1/sections";
  const response = await proxy.execute(query, queryParams);

  if (response) {
    const responseData = response.data;
    const sections = responseData.content || []; // Assuming content holds the sections
    for (const section of sections) {
      // Iterate over each access right and dispatch fetchAccessRights
      section.accessRights = await dispatch("fetchAccessRights", { sectionId: section.id });
    }
    const paginationDetails = extractPaginationDetails(responseData, queryParams);
    return commitData(commit, "setSections", responseData, paginationDetails);
  }
};

const fetchPermissions = async ({ commit }, { queryParams = {} } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "user-access-control/v1/permissions";
  const response = await proxy.execute(query, queryParams);

  if (response) {
    const responseData = response.data;
    const paginationDetails = extractPaginationDetails(responseData, queryParams);
    commitData(commit, "setPermissions", responseData, paginationDetails);
  }
};

const fetchAccessRights = async ({ commit }, { queryParams = {}, getById = false, accessRightId, sectionId } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  // Determine the query based on the parameters
  const query = getById
    ? `user-access-control/v1/access-rights/${accessRightId}`
    : sectionId
      ? `user-access-control/v1/sections/${sectionId}/access-rights`
      : "user-access-control/v1/access-rights";

  const response = await proxy.execute(query, queryParams);

  // Handle the response
  if (!response) return; // Early return if no response

  if (getById || sectionId) {
    return response.data; // Return data directly if fetching by ID
  }

  const responseData = response.data;
  const paginationDetails = extractPaginationDetails(responseData, queryParams);
  commitData(commit, "setAccessRights", responseData, paginationDetails);
};

const updateUserAccessRights = async (_, { userId, payload } = {}) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `user-access-control/v1/user/${userId}/access-rights`;
  return await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully updated permissions!",
  });
};

const fetchUserAccessRights = async ({ dispatch }, { userId, isUsersData } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `user-access-control/v1/user/${userId}/access-rights`;
  // const query = "user-access-control/v1/user/6d7a40b6-35a7-4466-b002-28376b3de988/access-rights";
  const response = await proxy.execute(query);
  if (response) {
    const responseData = response.data;
    if (isUsersData) {
      return responseData;
    } else {
      dispatch("setUserAccessRights", responseData);
    }
  }
};

const fetchUserRolesPermissionsUsers = async ({ commit }, { queryParams = {}, getById = false, accessRightId } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  // GET accounts/v1/users/access-rights?pageNumber=0&pageSize=10&sortName=createdAt, userName&sortOrder=DESC,ASC&userName=John QA1&orgId=e34b53c3-e29a-4e42-8499-90e368f60b93

  const query = "accounts/v1/users/user-access-control";
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const responseData = response.data;
    const paginationDetails = extractPaginationDetails(responseData, queryParams);
    commitData(commit, "setAccessRightsUsers", responseData, paginationDetails);
  }
};

export default {
  fetchSections,
  fetchPermissions,
  fetchAccessRights,
  updateUserAccessRights,
  fetchUserRolesPermissionsUsers,
  fetchUserAccessRights,
};
