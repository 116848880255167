const getLocationOrders = state => {
  return state.orders.sort((a, b) => {
    if (a.paidAt > b.paidAt) {
      return -1;
    }
    if (a.paidAt < b.paidAt) {
      return 1;
    }
    return 0;
  });
};

const getPageLength = state => {
  return state.pageLength;
};

export default {
  getLocationOrders,
  getPageLength,
};
