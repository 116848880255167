import { findIndex } from "lodash";

const setVendorTiers = (state, payload) => {
  state.vendorTiers = payload;
};

const updateVendorTiers = (state, payload) => {
  if (payload.isAdd) {
    state.vendorTiers.push(payload.data);
  } else if (payload.isDelete) {
    const selectedIndex = findIndex(state.vendorTiers, { id: payload.id });
    if (selectedIndex >= 0) {
      state.vendorTiers.splice(selectedIndex, 1);
    }
  }
};

export default {
  setVendorTiers,
  updateVendorTiers,
};
