import { isEmpty, get } from "lodash";
import axios from "@/lib/axios";

export default {
  namespaced: true,

  state: {
    leads: [],
    page: 1,
    pageLength: 5,
  },

  getters: {
    getOrgLeads: state => {
      return state.leads;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const leads = getters.getOrgLeads;
      const pageLength = getters.getPageLength;

      const a = page * pageLength - pageLength;
      const b = a + pageLength;

      return leads.slice(a, b);
    },
  },

  mutations: {
    setPage(state, payload) {
      state.page = payload;
    },
  },

  actions: {
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
  },
};
