import axios from "@/lib/axios";
import notification from "@/utils/notifications";

const fetchLocationPurchases = async ({ commit }, locationId) => {
  try {
    const response = await axios.get(
      `/orders/v1/buyer/${locationId}/purchase`,
    );
    commit("setLocationOrders", response.data);
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const clearLocationPurchases = async ({ commit }) => {
  commit("setLocationOrders", []);
};

const nextPage = ({ getters, commit }) => {
  commit("setPage", getters.getPage + 1);
};

const prevPage = ({ getters, commit }) => {
  commit("setPage", getters.getPage - 1);
};

const commitPage = ({ commit }, payload) => {
  commit("setPage", payload);
};

export default {
  fetchLocationPurchases,
  clearLocationPurchases,
  nextPage,
  prevPage,
  commitPage
};
