// eslint-disable-next-line import/no-cycle
import { isEmpty } from "lodash";
import notification from "@/utils/notifications";
import axios from "../lib/axios";

export default {
  namespaced: true,
  state: {
    storeProducts: [],
    selectedProducts: [],
    domain: "",
    storeDetails: {
      commission: 0,
      domainName: "",
      prodIds: "",
      domain: "labtest.health",
      selectedProducts: [],
    },
    onlineStorePage: 1,
    onlineStorePageLength: 12,
  },

  getters: {
    getStoreProducts: state => { return state.storeProducts; },
    getStoreDetails: state => { return state.storeDetails; },
    getAllCheckedProducts: state => { return state.selectedProducts; },
    getCurrentDomain: state => { return state.domain; },
    getNumberOfPages(state, getters) {
      const pages = Math.ceil(
        getters.getStoreProducts.length / getters.getPageLength,
      );
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.onlineStorePageLength;
    },
    getPage(state) {
      return state.onlineStorePage;
    },
    getPageResults(state, getters) {
      const page = getters.getPage;
      const results = getters.getStoreProducts;
      const pageLength = getters.getPageLength;

      const a = (page * pageLength) - pageLength;
      const b = a + pageLength;

      return results.slice(a, b);
    },
  },

  mutations: {
    setOnlineStorePage(state, payload) {
      state.onlineStorePage = payload;
    },
    updateProduct(state, payload) {
      const index = state.storeProducts.findIndex(
        item => { return item.id === payload.productId; },
      );
      state.storeProducts[index].isForSale = payload.value;
    },
    // UPDATING PRACTICE PRICE ONCE SAVED
    updatePracticeProduct(state, payload) {
      const productIndex = state.storeProducts.findIndex(item => { return item.id === payload.product.id; });
      state.storeProducts[productIndex].product = payload.price.toFixed(2);
    },
  },

  actions: {
    nextPage({ getters, commit }) {
      commit("setOnlineStorePage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setOnlineStorePage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setOnlineStorePage", payload);
    },
    updateStoreDetails: async (_, payload) => {
      const { orgId } = payload;
      const body = {
        orgId: payload.orgId,
        prodIds: payload.products,
        domainName: payload.domainName,
        commission: payload.commission,
      };
      try {
        const response = await axios.post(
          `/accounts/v1/landing/org/${orgId}/products`,
          body,
        );
        notification("success", "Successfully saved data");
        return response;
      } catch (error) {
        notification("warning", error);
      }
    },
    updateOrgPracticePrice: async (_, payload) => {
      try {
        const response = await axios.post(
          "/products/v1/org/practicePrice",
          payload,
        );
        notification("success", "Product successfully updated");
        if (response) {
          const { data } = response;
          return data;
        }
      } catch (error) {
        notification("warning", error);
      }
    },
  },
};
