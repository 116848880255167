import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchVendorInvoices = async ({ commit }, payload = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {};
  Object.keys(payload).forEach(key => {
    if (payload[key] !== undefined) {
      queryParams[key] = payload[key];
    }
  });
  const query = `orders/v1/vendor/${payload.vendorId}/invoice/pagenumber/${payload.pageNumber}/pagesize/${payload.pageSize}`;
  const response = await proxy.execute(query, queryParams);
  if (!payload.returnResponseOnly && response) {
    commit("setVendorInvoices", get(response, "data", []));
  }
  return response;
};

const generatePdf = async (_, { vendorId, lotNumber, barCodeType }) => {
  const proxy = new GetSimpatraServiceProxy();
  const downloadProductCode = barCodeType === "product";
  const additionalBaseUrl = barCodeType !== "downloadReturnLabel" ? `generateQRCode/${barCodeType}` : barCodeType;
  let query = `orders/v1/vendor/${vendorId}/lot/${lotNumber}/${additionalBaseUrl}/`;
  let additionalAxiosConfig = {};
  if (!downloadProductCode) {
    additionalAxiosConfig = {
      responseType: "blob",
    };
  }
  if (!downloadProductCode) query += "i";
  const response = await proxy.execute(query, null, null, additionalAxiosConfig);
  if (response) {
    return get(response, "data", null);
  } else {
    return null;
  }
};

export default {
  fetchVendorInvoices,
  generatePdf,
};
