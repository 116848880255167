const getPatientDetails = state => {
  return state.patientDetails;
};

const getCurrentLocationAppointments = state => {
  return state.appointments;
};

const getLocationUpcomingAppointments = state => {
  const today = new Date();
  return state.appointments
    .filter(appointment => {
      const aptDate = new Date(appointment.date);
      return aptDate > today;
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getLocationAppointmentsToday = state => {
  const today = new Date();
  return state.appointments
    .filter(appointment => {
      const aptDate = new Date(appointment.date);
      return (
        aptDate.getDate() === today.getDate() &&
        aptDate.getMonth() === today.getMonth() &&
        aptDate.getFullYear() === today.getFullYear()
      );
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getLocationAppointmentsThisMonth = state => {
  const today = new Date();
  return state.appointments
    .filter(appointment => {
      const aptDate = new Date(appointment.date);
      return aptDate.getMonth() === today.getMonth() && aptDate.getFullYear() === today.getFullYear();
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getLocationAppointmentsLastMonth = state => {
  const today = new Date();
  return state.appointments
    .filter(appointment => {
      const aptDate = new Date(appointment.date);
      return aptDate.getMonth() === today.getMonth() - 1 && aptDate.getFullYear() === today.getFullYear();
    })
    .sort((a, b) => {
      const dateOne = new Date(a.date);
      const dateTwo = new Date(b.date);
      if (dateOne > dateTwo) {
        return 1;
      }
      return -1;
    });
};

const getPatientInfoPayload = state => {
  return state.patientInfoPayload;
};

const getPatient = state => key => {
  return state[key];
}

export default {
  /**
   * APPOINTMENTS GETTER
   */
  getCurrentLocationAppointments,
  getLocationAppointmentsThisMonth,
  getLocationAppointmentsLastMonth,
  getLocationUpcomingAppointments,
  getLocationAppointmentsToday,
  getPatientDetails,
  getPatientInfoPayload,
  getPatient,
};
